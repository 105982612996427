var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "commentFooter",
      on: { mouseover: _vm.mouseover, mouseleave: _vm.mouseleave }
    },
    [
      _c("div", { staticClass: "commentTime" }, [
        _vm._v(_vm._s(_vm.comment.reviewDatetime))
      ]),
      _c("div", { staticClass: "commentOperatingArea" }, [
        _c(
          "div",
          {
            staticClass: "iconBox buttonStyle",
            on: {
              click: function($event) {
                return _vm.replyToThisPerson(_vm.comment)
              }
            }
          },
          [
            _c("span", {
              staticClass: "iconfont_Me icon-information iconStyle"
            })
          ]
        ),
        _c(
          "div",
          {
            staticClass: "buttonStyle",
            on: {
              click: function($event) {
                return _vm.reviewLikeStatus(_vm.comment)
              }
            }
          },
          [
            _c("div", { staticClass: "iconBox" }, [
              _c("span", {
                staticClass: "iconfont_Me iconStyle",
                class: _vm.comment.likeStatus
                  ? "icon-fabulous-fill"
                  : "icon-fabulous",
                style: { color: _vm.comment.likeStatus ? "#FC4C4C" : "" }
              })
            ]),
            _c("div", { staticClass: "likesCount" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.handleNewVersionCount(_vm.comment.likeCount)) +
                  "\n      "
              )
            ])
          ]
        ),
        _vm.isShowDelete && _vm.$store.getters.userInfo.manager_role == 1
          ? _c(
              "div",
              {
                staticClass: "iconBox buttonStyle",
                on: {
                  click: function($event) {
                    return _vm.deleteThisComment(_vm.comment)
                  }
                }
              },
              [
                _c("i", {
                  staticClass:
                    "iconStyle iconfont_Me icon-Iconly-Light-Delete-copy",
                  staticStyle: { "font-size": "14px" }
                })
              ]
            )
          : _vm._e(),
        _vm.isShowDelete && _vm.$store.getters.userInfo.manager_role != 1
          ? _c(
              "div",
              {
                staticClass: "iconBox buttonStyle",
                staticStyle: { "margin-top": "2px" },
                on: {
                  click: function($event) {
                    return _vm.complaintThisComment(_vm.comment)
                  }
                }
              },
              [
                _c("i", {
                  staticClass: "iconfont_Me icon-a-zu5510",
                  staticStyle: { "font-size": "15px", color: "#adadad" }
                })
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }