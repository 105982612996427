<script>
  export default {
    name: "Flashing",
    data() {
      return {};
    },
    
    render() {
      return (
        <div class="flashingStyle">
          {this.$slots.default}
        </div>
      );
    }
  }
</script>
<style lang="stylus" scoped>
  .flashingStyle
    height 100%;
    width 100%;
    background: transparent;
    text-transform: uppercase;
    color: black;
    overflow: hidden;
    position: relative;
    cursor pointer;
    &:after
      content: '';
      display: block;
      position: absolute;
      background: #cecece;
      top: -25%;
      width: 50px;
      opacity: 20%;
      left: -100px;
      height: 150%;
      transform: rotate(-45deg);
    &:hover:after
      left: 180%;
      transition: all 0.6s cubic-bezier(0.3, 1, 0.2, 1);
</style>