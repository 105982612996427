<template>
  <div
    class="inputBoxContent commentInputBox"
    v-loading="sendLoading"
    element-loading-spinner="el-icon-loading"
    element-loading-background="#FFFFFF"
  >
    <div class="avatarBox" v-if="showAvatar">
      <Avatar
        :path="avatarPath"
        :providerId="$store.getters.userInfo.id"
        :international="
          $store.getters.userInfo.country !== ''
            ? Number($store.getters.userInfo.country)
            : 0
        "
      ></Avatar>
    </div>
    <div class="inputBoxOuter">
      <div
        class="inputBox"
        :class="getFocus ? 'getFocusBorderStyle' : ''"
        :style="{
          minHeight: largeInput ? '64px' : '40px',
          alignItems: largeInput ? 'flex-start' : 'center',
          'padding-top': largeInput ? '18px' : '8px',
        }"
      >
        <div class="scrollBox">
          <div class="layoutBox">
            <el-tooltip
              effect="dark"
              :content="answerSomeone"
              placement="top"
              v-if="answerSomeone != ''"
            >
              <div class="answerSomeoneBlock">
                <span class="iconfont_Me icon-reply1 iconStyle"></span>
                <div class="answerSomeName">
                  {{ answerSomeone }}
                </div>
              </div>
            </el-tooltip>
            <div class="inputContent">
              <div class="scroll">
                <div class="inputArea">
                  <div
                    @blur="inputBlurFn"
                    @input="inputFn"
                    class="inputStyle"
                    ref="textarea"
                    contenteditable="true"
                    :placeholder="placeholderIcon"
                  ></div>
                </div>
              </div>
              <div v-if="showCommentImage" class="commentImage">
                <div class="pictureItem" v-if="commentImage">
                  <div class="el-image-wrapper">
                    <el-image
                      class="imelink-image__icon"
                      :src="
                        commentImage.path
                          ? commentImage.path
                          : require('../../pageViews/publishArticle/showUpload/grounGlass.png')
                      "
                      fit="cover"
                      style="width: 100%; height: 100%"
                    ></el-image>
                    <el-progress
                      v-if="stringToNumber(commentImage.progress) < 100"
                      type="circle"
                      class="imelink-message-file__progress"
                      :percentage="stringToNumber(commentImage.progress)"
                      color="#fff"
                      :stroke-width="2"
                      text-inside
                      :width="40"
                    />
                  </div>
                  <!-- 删除按钮 -->
                  <div class="deleteThisPicture" @click="deleteThisPicture">
                    <span class="iconfont_Me icon-x deleteIconStyle"></span>
                  </div>
                  <!-- 取消上传按钮 -->
                  <div
                    class="deleteThisPicture cancleStyle"
                    @click.stop="$emit('needCanclePictrue')"
                    v-if="stringToNumber(commentImage.progress) < 100"
                  >
                    <span class="iconfont_Me icon-x deleteIconStyle"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="operationBox" v-if="withPictures">
          <emotion width="400" @choose="handEmoChoose">
            <div class="operationButton" :title="$t('emoji')">
              <span class="iconfont_Me icon-emoj iconStyle"></span>
            </div>
          </emotion>
          <div class="operationButton" @click="uploadImage">
            <span class="iconfont_Me icon-picture1 iconStyle"></span>
          </div>
          <el-popover
            placement="top"
            width="470"
            trigger="click"
            v-model="emojiVisible"
            popper-class="emojisPopover"
          >
            <div slot="reference" class="operationButton" @click="getEmoji">
              <span class="iconfont_Me icon-a-zu10116 iconStyle"></span>
            </div>
            <div
              class="emojis"
              v-loading="emojisLoading"
              element-loading-spinner="el-icon-loading"
              element-loading-background="#FFFFFF"
            >
              <img
                v-for="item in fileInfos"
                class="emojiImage"
                :src="item.path"
                @click.stop="selectEmoji(item.path)"
              />
            </div>
          </el-popover>
        </div>
      </div>
      <div
        class="submitButton"
        :class="getFocus ? 'getFocusStyle' : ''"
        :style="{
          width: '64px',
          height: '64px',
        }"
        @click.stop="triggerSubmit"
      >
        <span class="iconfont_Me icon-check iconStyle"></span>
      </div>
      <div v-show="false">
        <el-upload
          v-show="false"
          ref="uploadPicture"
          action="/"
          accept="image/*"
          :multiple="false"
          :http-request="handlerUpload"
          :before-upload="beforePictrueUpload"
        >
        </el-upload>
      </div>
    </div>
  </div>
</template>
<script>
import { handlerUploadFileToQiniu, getEmojiFromQiniu } from "@/api/qiniu";
import Avatar from "../avatarDisplay";
import { env } from "@/utils";
export default {
  components: {
    Avatar,
  },
  props: {
    // 是否展示用户头像
    showAvatar: {
      type: Boolean,
      default: false,
    },
    // 回复的人的姓名
    answerSomeone: {
      type: String,
      default: "",
    },
    // 输入框高度根据内容自动变高
    autoHeight: {
      type: Boolean,
      default: true,
    },
    // 大号输入框
    largeInput: {
      type: Boolean,
      default: false,
    },
    // 发送评论加载框
    sendLoading: {
      type: Boolean,
      default: false,
    },
    // 可以发送图片
    withPictures: {
      type: Boolean,
      default: false,
    },
    setFocus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      // 获取到焦点
      getFocus: false,
      // 默认文本
      placeholderIcon: "\ue6a1",
      // 记录失去焦点时，当前光标的位置
      prevCursorPosition: 0,
      // 上传内容
      commentImage: null,
      showCommentImage: false,
      // 表情包
      fileInfos: [],
      emojiVisible: false,
      emojisLoading: false,
    };
  },
  watch: {
    // 默认获取焦点
    setFocus: {
      handler(val, old) {
        if (val && val != old) {
          try {
            this.$nextTick(() => {
              const textarea = this.$refs.textarea;
              textarea.focus();
            });
          } catch (error) {}
        }
      },
      deep: true,
      immediate: true,
    },
    // 回复人的姓名变化的时候，清空输入框内的信息
    answerSomeone: {
      handler(val, old) {
        if (val && val != "" && val != old) {
          this.$refs.textarea.innerHTML = "";
        }
      },
      deep: true,
    },
    "commentImage.path": {
      async handler(val, old) {
        if (val && val !== old) {
          this.$emit("resetLayout");
        }
      },
      deep: true,
      immediate: true,
    },
  },
  computed: {
    // 用户的本地头像
    avatarPath() {
      return this.$store.getters.userInfo.avatar;
    },
  },
  methods: {
    // 获取当前已输入的内容
    getInputContent() {
      return {
        innerHTML: this.$refs.textarea.innerHTML,
        commentImage: this.commentImage,
        prevCursorPosition: this.prevCursorPosition,
      };
    },
    // 将上次输入的内容写入
    async setInputContent(params) {
      const textarea = this.$refs.textarea;
      textarea.innerHTML = params.innerHTML;
      this.commentImage = params.commentImage;
      this.prevCursorPosition = params.prevCursorPosition;
      await this.$forceUpdate();
      this._setCursorPosition(textarea, this.prevCursorPosition);
      this.getFocus = true;
    },
    // 获取焦点触发监听
    inputFocusFn() {
      this.$emit("commentInputFocus");
    },
    deleteThisPicture() {
      this.commentImage = null;
      this.showCommentImage = false;
    },
    stringToNumber(string) {
      return parseInt(string);
    },
    // 触发提交评论事件
    triggerSubmit() {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      if (
        this.globalTrim(this.$refs.textarea.innerHTML.replace(/&nbsp;/g, "")) ==
        ""
      ) {
        this.$refs.textarea.innerHTML = "";
      }
      if (this.getFocus) {
        const content = this.getContentFromInput();
        if (content.length > 500) {
          this.$message({
            type: "error",
            message: "≤500",
          });
          return;
        }
        let param = {
          commentText: content,
        };
        if (this.commentImage) {
          param.imgUrl = this.commentImage.path;
          this.commentImage = null;
          this.showCommentImage = false;
        }
        this.$emit("triggerSubmit", param);
        this.$nextTick(() => {
          this.getFocus = false;
          this.$refs.textarea.innerHTML = "";
        });
      }
    },
    handEmoChoose(e, emo) {
      const textarea = this.$refs.textarea;
      this._setCursorPosition(textarea, this.prevCursorPosition);
      this._insertElementToCursorAfter(
        textarea,
        `<img face-name="${emo.name}" src='${emo.src}' />&nbsp;`
      );
      this.getFocus = true;
    },
    filterEmojiImage(content, trim = "&nbsp;") {
      if (!content) return content;
      return content.replace(
        // eslint-disable-next-line no-useless-escape
        new RegExp(`<img face-name=\"([^\"]*?)\" [^>]*>${trim}`, "gi"),
        "[!$1]"
      );
    },
    async inputFn(e) {
      try {
        if (
          [
            "deleteContentForward",
            "deleteContentBackward",
            "insertParagraph",
          ].includes(e.inputType)
        ) {
          return;
        }
      } catch (error) {}
      if (
        this.globalTrim(this.$refs.textarea.innerHTML.replace(/&nbsp;/g, "")) !=
        ""
      ) {
        // 记录当前光标位置
        this.prevCursorPosition = this._getCursorPosition(e.target);
        const reg = /style=".*?"/g;
        if (reg.test(this.$refs.textarea.innerHTML)) {
          this.$refs.textarea.innerHTML = this.$refs.textarea.innerHTML.replace(
            reg,
            ""
          );
        }
        const reg_img = /<img.*?>/g;
        if (reg_img.test(this.$refs.textarea.innerHTML)) {
          this.$refs.textarea.innerHTML = this.$refs.textarea.innerHTML.replace(
            reg_img,
            function(match) {
              if (match.indexOf("face-name=") !== -1) {
                return match;
              } else {
                return "";
              }
            }
          );
          const textarea = this.$refs.textarea;
          this._setCursorPosition(textarea, this.prevCursorPosition);
        }
        if (this.$refs.textarea.innerHTML !== "") {
          this.getFocus = true;
        }
      } else {
        this.getFocus = this.commentImage ? true : false;
      }
      this.$emit("resetLayout");
    },
    getContentFromInput() {
      let content = this.filterEmojiImage(this.$refs.textarea.innerHTML)
        .replace(/<div><br><\/div>/g, "\n")
        .replace(/^(<div>)/g, "")
        .replace(/\t/g, " ")
        .replace(/<\/div><div>/g, "\n")
        .replace(/<div>/g, "\n")
        .replace(/<\/div>/g, "\n")
        .replace(/<br\s*\/?>/gi, "\n")
        .replace(/&nbsp;/g, " ")
        .replace(/<\/?.+?\/?>/g, "");
      if (content == "") {
        return "";
      }
      return this.filterEmojiImage(content);
    },
    async inputBlurFn(e) {
      this.prevCursorPosition = this._getCursorPosition(e.target);
    },
    //在光标之后插入元素
    _insertElementToCursorAfter(cursorElem, elem) {
      let range, node;
      if (!cursorElem.hasfocus) {
        cursorElem.focus();
      }
      if (window.getSelection && window.getSelection().getRangeAt) {
        range = window.getSelection().getRangeAt(0);
        range.collapse(false);
        node = range.createContextualFragment(elem);
        let c = node.lastChild;
        range.insertNode(node);
        if (c) {
          range.setEndAfter(c);
          range.setStartAfter(c);
        }
        let j = window.getSelection();
        j.removeAllRanges();
        j.addRange(range);
      } else if (document.selection && document.selection.createRange) {
        document.selection.createRange().pasteHTML(elem);
      }
    },
    //设置光标位置
    _setCursorPosition(element, pos) {
      const createRange = (node, chars, range) => {
        if (!range) {
          range = document.createRange();
          range.selectNode(node);
          range.setStart(node, 0);
        }
        if (chars.count === 0) {
          range.setEnd(node, chars.count);
        } else if (node && chars.count > 0) {
          if (node.nodeType === Node.TEXT_NODE) {
            if (node.textContent.length < chars.count) {
              chars.count -= node.textContent.length;
            } else {
              range.setEnd(node, chars.count);
              chars.count = 0;
            }
          } else {
            for (let lp = 0; lp < node.childNodes.length; lp++) {
              range = createRange(node.childNodes[lp], chars, range);
              if (chars.count === 0) {
                break;
              }
            }
          }
        }
        return range;
      };
      if (pos >= 0) {
        const selection = window.getSelection();
        const range = createRange(element.parentNode, {
          count: pos,
        });
        if (range) {
          range.collapse(false);
          selection.removeAllRanges();
          selection.addRange(range);
        }
      }
    },
    //获取光标位置
    _getCursorPosition(element) {
      var caretOffset = 0;
      var doc = element.ownerDocument || element.document;
      var win = doc.defaultView || doc.parentWindow;
      var sel;
      if (typeof win.getSelection != "undefined") {
        sel = win.getSelection();
        if (sel.rangeCount > 0) {
          var range = win.getSelection().getRangeAt(0);
          var preCaretRange = range.cloneRange();
          preCaretRange.selectNodeContents(element);
          preCaretRange.setEnd(range.endContainer, range.endOffset);
          caretOffset = preCaretRange.toString().length;
        }
      } else if ((sel = doc.selection) && sel.type != "Control") {
        var textRange = sel.createRange();
        var preCaretTextRange = doc.body.createTextRange();
        preCaretTextRange.moveToElementText(element);
        preCaretTextRange.setEndPoint("EndToEnd", textRange);
        caretOffset = preCaretTextRange.text.length;
      }
      return caretOffset;
    },
    // 操作
    uploadImage() {
      this.$refs.uploadPicture.$children[0].$refs.input.click();
    },
    // 获取表情
    async getEmoji() {
      let params = {
        bucket: "tfile",
        prefix: `_cartoon/gif/${
          localStorage.getItem("langCode") == 1 ? "zh" : "en"
        }`,
      };
      this.emojisLoading = true;
      const result = await getEmojiFromQiniu(params);
      this.emojisLoading = false;
      if (result.code == 200) {
        if (result.data.data.fileInfos.length !== 0) {
          this.fileInfos = result.data.data.fileInfos
            .filter(
              (fileInfo) => fileInfo.key.charAt(fileInfo.key.length - 1) !== "/"
            )
            .map((fileInfo) => {
              fileInfo.path = `${env("UPLOAD_IMAGE")}/${fileInfo.key}`;
              return fileInfo;
            });
        }
      } else {
        this.emojiVisible = false;
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
    // 选择表情
    async selectEmoji(path) {
      this.showCommentImage = true;
      this.$emit("resetLayout");
      this.commentImage = {
        progress: "100.00",
        path: path,
      };
      this.$nextTick(() => {
        const textarea = this.$refs.textarea;
        this._setCursorPosition(textarea, this.prevCursorPosition);
        this.getFocus = true;
        this.emojiVisible = false;
      });
    },
    // 开始调用隐藏的上传图片按钮
    handlerUpload(files) {
      this.showCommentImage = true;
      this.$emit("resetLayout");
      handlerUploadFileToQiniu(files).then(async (item) => {
        this.getFocus = true;
        this.commentImage = item;
        const textarea = this.$refs.textarea;
        this._setCursorPosition(textarea, this.prevCursorPosition);
        this.$refs.uploadPicture.clearFiles();
      });
    },
    // 在上传图片以前执行检查
    beforePictrueUpload(file, fileList) {
      if (
        this.matchFileSuffixType(file.name) === "image" &&
        file.size > 0 &&
        (file.size / (1024 * 1024)).toFixed(2) < 200
      ) {
        return true;
      }
      this.$message({
        type: "error",
        message: "<200M",
      });
      return false;
    },
  },
};
</script>
<style lang="stylus" scoped>
.inputBoxContent
  width 100%;
  min-height 40px;
  overflow hidden;
  display flex;
  align-items flex-start;
  justify-content flex-start;
  .avatarBox
    width 40px;
    height 40px;
    flex-shrink 0;
    margin-right 14px;
  .inputBoxOuter
    flex 1;
    min-width 0;
    display flex;
    align-items flex-end;
    justify-content flex-end;
    .getFocusBorderStyle
      border 1px solid #33CC66 !important;
    .getFocusStyle
      cursor pointer !important;
      background #33CC66 !important;
    .inputBox
      flex 1;
      min-width 0;
      box-sizing border-box;
      border-radius 4px;
      border 1px solid #CCCCCC;
      overflow hidden;
      text-align: right;
      .answerSomeoneBlock
        height 100%;
        padding 3px 0 0 8px;
        flex-shrink 0;
        max-width 150px;
        display flex;
        color #33CC66;
        font-size 14px;
        align-items center;
        .iconStyle
          color #33CC66;
          font-size 20px;
        .answerSomeName
          overflow hidden;
          white-space: nowrap;
          box-sizing border-box;
          text-overflow:ellipsis;
          user-select none;
      .scrollBox
        width: 100%;
        overflow hidden;
        .layoutBox
          width 100%;
          overflow hidden;
          display: flex;
          align-items: flex-start;
          .inputContent
            flex 1;
            overflow hidden;
            .scroll
              width:100%;
              max-height: 80px;
              overflow-x hidden;
              overflow-y auto;
              &::-webkit-scrollbar
                width 4px;
              &::-webkit-scrollbar-thumb
                border-radius: 2px;
                box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
                background: #ADADAD;
              &::-webkit-scrollbar-track
                box-shadow: inset 0 0 5px rgba(0,0,0,0);
                border-radius: 0;
                background: transparent;
              .inputArea
                overflow: hidden;
                width:100%;
                min-height: 24px;
                .inputStyle
                  width:100%;
                  font-size: 14px;
                  line-height: 24px;
                  padding: 0 12px;
                  overflow-y: auto;
                  height: 100%;
                  outline: none;
                  scrollbar-light();
                  word-wrap: break-word;
                  text-align: left;
                  margin-bottom: 5px;
            .commentImage
              width: 100%;
              height: 100px;
              text-align: left;
              overflow hidden;
              box-sizing: border-box;
              padding: 0 12px;
              .pictureItem
                width: 100px;
                margin-right: 4px;
                position: relative;
                display: inline-block;
                .el-image-wrapper
                  border-radius: 8px;
                  overflow: hidden;
                  background: #F0F0F0;
                  height: 100px !important;
                  box-sizing: border-box;
                  position: relative;
                .imelink-message-file__progress
                  .el-progress-circle
                    background: transparent !important;
                    position: relative;
                  .el-progress-circle::after
                    font-family: element-icons !important;
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    color: #fff;
                    font-size: 25px;
                    transform: translate(-50%, -50%);
                .el-image
                  width: 100%;
                  height: 100%;
                .deleteThisPicture
                  top: 3px;
                  right: 3px;
                  width: 18px;
                  height: 18px;
                  cursor: pointer;
                  border-radius: 50%;
                  position: absolute;
                  background: rgba(0, 0, 0, 0.2);
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  .deleteIconStyle
                    color: #FFFFFF;
                    font-size: 12px;
                .cancleStyle
                  background: red;
                .addIconStyle
                  top: 50%;
                  left: 50%;
                  color: #8F8F8F;
                  font-size: 30px;
                  position: absolute;
                  font-weight: bold;
                  transform: translate3d(-50%, -50%, 0);
      .operationBox
        display: inline-flex;
        height: 100%;
        width: 88px;
        align-items: center;
        justify-content: space-between;
        margin-right: 12px;
        .operationButton
          width: 24px;
          height: 24px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor pointer;
          .iconStyle
            font-size: 24px;
            color #333333;
    .submitButton
      width 40px;
      height 40px;
      cursor not-allowed;
      flex-shrink 0;
      margin-left 6px;
      border-radius 4px;
      background #CCCCCC;
      display flex;
      align-items center;
      justify-content center;
      .iconStyle
        color #FFFFFF;
        font-size 20px;
</style>
<style lang="stylus">
.emojisPopover
  .emojis
    width: 100%;
    height: 230px;
    overflow-x hidden;
    overflow-y auto;
    &::-webkit-scrollbar
      width 0px;
    &::-webkit-scrollbar-thumb
      border-radius: 2px;
      box-shadow: inset 0 0 5px rgba(100,100,100,0.2);
      background: #ADADAD;
    &::-webkit-scrollbar-track
      box-shadow: inset 0 0 5px rgba(0,0,0,0);
      border-radius: 0;
      background: transparent;
    .emojiImage
      width: 82px;
      height: 82px;
      float left;
      border-radius: 12px
      margin: 0 8.5px 8.5px 0;
      &:nth-of-type(5n)
        margin: 0 0 8.5px 0;
</style>
