<template>
  <div class="commentFooter" @mouseover="mouseover" @mouseleave="mouseleave">
    <div class="commentTime">{{ comment.reviewDatetime }}</div>
    <div class="commentOperatingArea">
      <div class="iconBox buttonStyle" @click="replyToThisPerson(comment)">
        <span class="iconfont_Me icon-information iconStyle"></span>
      </div>
      <div class="buttonStyle" @click="reviewLikeStatus(comment)">
        <div class="iconBox">
          <span
            class="iconfont_Me iconStyle"
            :class="comment.likeStatus ? 'icon-fabulous-fill' : 'icon-fabulous'"
            :style="{ color: comment.likeStatus ? '#FC4C4C' : '' }"
          ></span>
        </div>
        <div class="likesCount">
          {{ handleNewVersionCount(comment.likeCount) }}
        </div>
      </div>
      <!-- 管理员有删除操作 -->
      <div
        class="iconBox buttonStyle"
        v-if="isShowDelete && $store.getters.userInfo.manager_role == 1"
        @click="deleteThisComment(comment)"
      >
        <i
          class="iconStyle iconfont_Me  icon-Iconly-Light-Delete-copy"
          style="font-size: 14px"
        ></i>
      </div>

      <!-- 非管理员有投诉操作 -->
      <div
        class="iconBox buttonStyle"
        style="margin-top: 2px"
        v-if="isShowDelete && $store.getters.userInfo.manager_role != 1"
        @click="complaintThisComment(comment)"
      >
        <i
          class="iconfont_Me icon-a-zu5510"
          style="font-size: 15px; color: #adadad"
        ></i>
      </div>
    </div>
  </div>
</template>
<script>
import { reviewLikeStatus } from "@/api/newVersion";
import flashingVue from "../../../components/common/sparePartsStyle/flashing.vue";
export default {
  components: {},
  props: {
    // 主评论内容
    comment: {
      type: Object,
      default: () => {
        return {};
      },
    },
    // 点赞类型
    type: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      reasonList: [],
      isShowDelete: false,
    };
  },
  methods: {
    mouseover() {
      this.isShowDelete = true;
    },
    mouseleave() {
      this.isShowDelete = false;
    },
    // 点击回复这个用户
    replyToThisPerson(comment) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      this.$emit("replyToThisPerson", comment);
    },
    // 管理员点击删除此条评论
    deleteThisComment(comment) {
      this.$store.commit("setDeleteType", "comment");
      this.$store.commit("setDeleteReviewId", comment.reviewId);
      this.$store.commit("setDeleteDialogVisible", true);
    },
    // 普通用户投诉此条帖子评论
    complaintThisComment(comment) {
      let type = 11;
      if (this.$route.name == "personalCenter") {
        type = 31;
      }
      if (this.$route.name == "groupCenter") {
        type = 41;
      }
      this.$store.commit("setComplaintType", type);
      this.$store.commit("setComment", comment);
      this.$store.commit("setComplaintId", comment.reviewId);
      this.$store.commit("setComplaintDialogVisible", true);
    },
    // 点击改变点赞状态
    async reviewLikeStatus(comment) {
      if (!this.$store.getters.userInfo.id) {
        this.$store.commit("setLoginAndRegisterDialog", true);
        return;
      }
      let params = {
        operation: this.type,
      };
      let result = await reviewLikeStatus(comment.reviewId, params);
      if (result.code === 200) {
        this.comment.likeStatus = !this.comment.likeStatus;
        this.comment.likeStatus
          ? this.comment.likeCount++
          : this.comment.likeCount--;
      } else {
        this.$message({
          type: "error",
          message: result.message,
        });
      }
    },
  },
};
</script>
<style lang="stylus" scoped>
.commentFooter {
  width: 100%;
  margin-top: 5px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  .commentTime {
    height: 100%;
    min-width: 0;
    flex-shrink: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #8F8F8F;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .commentOperatingArea {
    height: 100%;
    min-width: 85px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .buttonStyle {
      cursor: pointer;
      margin-left: 50px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      &:last-child {
        margin-right: 0;
      }

      .likesCount {
        height: 22px;
        min-width: 0;
        flex: 1;
        display: flex;
        font-size: 12px;
        color: #ADADAD;
        align-items: center;
        justify-content: flex-start;
        transition: color 0.3s;

        &:hover {
          color: #333333;
        }
      }
    }

    .iconBox {
      width: 22px;
      height: 22px;
      display: flex;
      flex-shrink: 0;
      align-items: center;
      justify-content: center;

      .iconStyle {
        color: #ADADAD;
        font-size: 18px;
        transition: color 0.3s;

        &:hover {
          color: #333333;
        }
      }
    }
  }
}
</style>
