var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.sendLoading,
          expression: "sendLoading"
        }
      ],
      staticClass: "inputBoxContent commentInputBox",
      attrs: {
        "element-loading-spinner": "el-icon-loading",
        "element-loading-background": "#FFFFFF"
      }
    },
    [
      _vm.showAvatar
        ? _c(
            "div",
            { staticClass: "avatarBox" },
            [
              _c("Avatar", {
                attrs: {
                  path: _vm.avatarPath,
                  providerId: _vm.$store.getters.userInfo.id,
                  international:
                    _vm.$store.getters.userInfo.country !== ""
                      ? Number(_vm.$store.getters.userInfo.country)
                      : 0
                }
              })
            ],
            1
          )
        : _vm._e(),
      _c("div", { staticClass: "inputBoxOuter" }, [
        _c(
          "div",
          {
            staticClass: "inputBox",
            class: _vm.getFocus ? "getFocusBorderStyle" : "",
            style: {
              minHeight: _vm.largeInput ? "64px" : "40px",
              alignItems: _vm.largeInput ? "flex-start" : "center",
              "padding-top": _vm.largeInput ? "18px" : "8px"
            }
          },
          [
            _c("div", { staticClass: "scrollBox" }, [
              _c(
                "div",
                { staticClass: "layoutBox" },
                [
                  _vm.answerSomeone != ""
                    ? _c(
                        "el-tooltip",
                        {
                          attrs: {
                            effect: "dark",
                            content: _vm.answerSomeone,
                            placement: "top"
                          }
                        },
                        [
                          _c("div", { staticClass: "answerSomeoneBlock" }, [
                            _c("span", {
                              staticClass: "iconfont_Me icon-reply1 iconStyle"
                            }),
                            _c("div", { staticClass: "answerSomeName" }, [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.answerSomeone) +
                                  "\n              "
                              )
                            ])
                          ])
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "inputContent" }, [
                    _c("div", { staticClass: "scroll" }, [
                      _c("div", { staticClass: "inputArea" }, [
                        _c("div", {
                          ref: "textarea",
                          staticClass: "inputStyle",
                          attrs: {
                            contenteditable: "true",
                            placeholder: _vm.placeholderIcon
                          },
                          on: { blur: _vm.inputBlurFn, input: _vm.inputFn }
                        })
                      ])
                    ]),
                    _vm.showCommentImage
                      ? _c("div", { staticClass: "commentImage" }, [
                          _vm.commentImage
                            ? _c("div", { staticClass: "pictureItem" }, [
                                _c(
                                  "div",
                                  { staticClass: "el-image-wrapper" },
                                  [
                                    _c("el-image", {
                                      staticClass: "imelink-image__icon",
                                      staticStyle: {
                                        width: "100%",
                                        height: "100%"
                                      },
                                      attrs: {
                                        src: _vm.commentImage.path
                                          ? _vm.commentImage.path
                                          : require("../../pageViews/publishArticle/showUpload/grounGlass.png"),
                                        fit: "cover"
                                      }
                                    }),
                                    _vm.stringToNumber(
                                      _vm.commentImage.progress
                                    ) < 100
                                      ? _c("el-progress", {
                                          staticClass:
                                            "imelink-message-file__progress",
                                          attrs: {
                                            type: "circle",
                                            percentage: _vm.stringToNumber(
                                              _vm.commentImage.progress
                                            ),
                                            color: "#fff",
                                            "stroke-width": 2,
                                            "text-inside": "",
                                            width: 40
                                          }
                                        })
                                      : _vm._e()
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  {
                                    staticClass: "deleteThisPicture",
                                    on: { click: _vm.deleteThisPicture }
                                  },
                                  [
                                    _c("span", {
                                      staticClass:
                                        "iconfont_Me icon-x deleteIconStyle"
                                    })
                                  ]
                                ),
                                _vm.stringToNumber(_vm.commentImage.progress) <
                                100
                                  ? _c(
                                      "div",
                                      {
                                        staticClass:
                                          "deleteThisPicture cancleStyle",
                                        on: {
                                          click: function($event) {
                                            $event.stopPropagation()
                                            return _vm.$emit(
                                              "needCanclePictrue"
                                            )
                                          }
                                        }
                                      },
                                      [
                                        _c("span", {
                                          staticClass:
                                            "iconfont_Me icon-x deleteIconStyle"
                                        })
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ])
                      : _vm._e()
                  ])
                ],
                1
              )
            ]),
            _vm.withPictures
              ? _c(
                  "div",
                  { staticClass: "operationBox" },
                  [
                    _c(
                      "emotion",
                      {
                        attrs: { width: "400" },
                        on: { choose: _vm.handEmoChoose }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "operationButton",
                            attrs: { title: _vm.$t("emoji") }
                          },
                          [
                            _c("span", {
                              staticClass: "iconfont_Me icon-emoj iconStyle"
                            })
                          ]
                        )
                      ]
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "operationButton",
                        on: { click: _vm.uploadImage }
                      },
                      [
                        _c("span", {
                          staticClass: "iconfont_Me icon-picture1 iconStyle"
                        })
                      ]
                    ),
                    _c(
                      "el-popover",
                      {
                        attrs: {
                          placement: "top",
                          width: "470",
                          trigger: "click",
                          "popper-class": "emojisPopover"
                        },
                        model: {
                          value: _vm.emojiVisible,
                          callback: function($$v) {
                            _vm.emojiVisible = $$v
                          },
                          expression: "emojiVisible"
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "operationButton",
                            attrs: { slot: "reference" },
                            on: { click: _vm.getEmoji },
                            slot: "reference"
                          },
                          [
                            _c("span", {
                              staticClass:
                                "iconfont_Me icon-a-zu10116 iconStyle"
                            })
                          ]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "loading",
                                rawName: "v-loading",
                                value: _vm.emojisLoading,
                                expression: "emojisLoading"
                              }
                            ],
                            staticClass: "emojis",
                            attrs: {
                              "element-loading-spinner": "el-icon-loading",
                              "element-loading-background": "#FFFFFF"
                            }
                          },
                          _vm._l(_vm.fileInfos, function(item) {
                            return _c("img", {
                              staticClass: "emojiImage",
                              attrs: { src: item.path },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.selectEmoji(item.path)
                                }
                              }
                            })
                          }),
                          0
                        )
                      ]
                    )
                  ],
                  1
                )
              : _vm._e()
          ]
        ),
        _c(
          "div",
          {
            staticClass: "submitButton",
            class: _vm.getFocus ? "getFocusStyle" : "",
            style: {
              width: "64px",
              height: "64px"
            },
            on: {
              click: function($event) {
                $event.stopPropagation()
                return _vm.triggerSubmit.apply(null, arguments)
              }
            }
          },
          [_c("span", { staticClass: "iconfont_Me icon-check iconStyle" })]
        ),
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: false,
                expression: "false"
              }
            ]
          },
          [
            _c("el-upload", {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: false,
                  expression: "false"
                }
              ],
              ref: "uploadPicture",
              attrs: {
                action: "/",
                accept: "image/*",
                multiple: false,
                "http-request": _vm.handlerUpload,
                "before-upload": _vm.beforePictrueUpload
              }
            })
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }